import React from "react";
import { memo } from "react";
import FullLogo from "web/components/molecules/FullLogo/FullLogo";
import Navigator from "web/components/organisms/Navigator";
import Btn from "web/components/atoms/Btn";

const Footer = ({ navs }) => {
  const mainNav = navs && navs["main-nav"];
  const legalNav = navs && navs["footer-nav"];
  return (
    <footer className="main-footer">
      <div className="main-footer__inner ctn ctn-large">
        <div className="main-footer__top">
          <div className="main-footer__top__nav">
            <FullLogo />
            <Navigator items={mainNav} />
          </div>
          <div className="main-footer__top__map">
            <div className="main-footer__top__map__ctn">
              <div className="main-footer__top__map__inner">
                <iframe src="https://maps.google.com/maps?q=46.839322, -0.490316&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
              </div>
            </div>
          </div>
          <div className="main-footer__top__contact">
            <div className="main-footer__top__contact__actions">
              <Btn
                appearance="white btn-white-full"
                icon="call"
                to="tel:+33 6 38 32 58 45"
                type="web"
              >
                Appeler
              </Btn>
              <Btn
                appearance="white btn-white-full"
                icon="schedule"
                to="https://www.doctolib.fr/chiropracteur/bressuire/adrien-rollet/booking?utm_campaign=website-button&amp;utm_source=adrien-rollet-website-button&amp;utm_medium=referral&amp;utm_content=withoutpreview-blue-floating-bottom-right&amp;utm_term=adrien-rollet"
                type="web"
                target="_blank"
              >
                Prendre RDV
              </Btn>
            </div>
            <div className="main-footer__top__contact__mail">
              <Btn
                appearance="default"
                icon="mail"
                to="mailto:contact@chiro-bressuire.fr"
                type="web"
              >
                contact@chiro-bressuire.fr
              </Btn>
            </div>
          </div>
        </div>
        <div className="main-footer__middle">
          <div className="main-footer__middle__social">
            <Btn
              appearance="default"
              icon="facebook"
              to="https://www.facebook.com/profile.php?id=100085444157831"
              type="web"
              target="_blank"
            />
          </div>
        </div>
        <div className="main-footer__bottom">
          <div className="main-footer__bottom__rights">
            Adrien Rollet | Tous droits réservés. © 2024
          </div>
          <div className="main-footer__bottom__nav">
            <Navigator items={legalNav} />
          </div>
        </div>
      </div>

      {/* <div>
        <FullLogo />
        <p className="main-footer__copyright">Copyright</p>
        <Navigator items={nav} />
      </div> */}
    </footer>
  );
};

export default memo(Footer);
